<template>
  <v-card
    flat
    height="100%"
    class="d-flex flex-column grey darken-2"
  >
    <div>
        <img
          data-blink-ops="scale-crop: 600x400; scale-crop-position: smart_faces_points;"
          :data-blink-uuid="uuid"
          class="rounded-t"
          width="100%"
          height="auto"
        />
        <v-card-title
          class="justify-center text-h5 grey--text text--lighten-2 text-uppercase font-weight-medium"
        >
          {{ title }}
        </v-card-title>
        <v-card-text>
          <div
            class="text-subtitle-1 font-weight-bold grey--text text--lighten-3 grow"
          >
            {{ content }}
          </div>
        </v-card-text>
    </div>
        <v-spacer />
        <v-card-actions
          class="pa-0 primary"
        >
          <v-btn
           v-if="route"
            block
            class="rounded-t-0 white--text primary"
            elevation="0"
            :to="route"
          >
            Learn More
          </v-btn>
        </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    uuid: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    },
    route: {
      type: String
    }
  }
}
</script>

<style scoped>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
